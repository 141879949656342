<template>
  <div>
    <h2 class="ds-heading ds-margin-bottom-24">
      {{ title }}
    </h2>
    <div
        v-if="filterItems.length > 0"
        class="filter ds-md-margin-bottom-24"
    >
      <DsDropdown
          :label="filterLabel"
          :text="filterLabel"
          :options="filterItems"
          with-border
          @dropdown-item-clicked="(event) => selectedFilter = { ...event }"
      />
    </div>

    <DsStackedList v-if="computedItems.length" :items="computedItems" />
  </div>
</template>

<script setup>
import { DsDropdown, DsStackedList } from 'awds'

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  filterItems: {
    type: Array,
    default: () => ([])
  },
  items: {
    type: Array,
    required: true,
    validator: array => array.every(obj => ['year', 'title', 'eyebrow', 'links'].every(key => key in obj))
  }
})

const eventItems = computed(() => {
  return props.items
})

const selectedFilter = ref(null)


const computedItems = computed(() => {
  if (selectedFilter.value) {
    return props.items.filter(item => item.year === selectedFilter.value.value)
  }
  return eventItems.value
})

const filterLabel = computed(() => {
  return selectedFilter.value?.text
})

onMounted(() => {
  if (props.filterItems.length > 0) {
     selectedFilter.value = { ...props.filterItems?.[0] }
  }
})

</script>
<style lang="scss" scoped>
@import 'awds/foundation-mixins.scss';

.filter {
  width: 100%;

  @include screen('medium') {
    width: 87px;
  }

  :deep(.ds-dropdown__wrapper) {
    padding: 0;
  }
}
</style>
